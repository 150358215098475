<template>
  <v-footer color="#FFFFFF" elevation="10">
    <v-row  no-gutters>
      <v-col cols="12">
        <v-divider class="dividerStyling"></v-divider>
      </v-col>
      <!-- EU Logo -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" v-if="$vuetify.breakpoint.mdAndDown">
        <!-- <v-img src="img/EU_POS.png" contain max-width="300"></v-img> -->
      </v-col>
      <v-col v-else cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
        <!-- <v-img src="img/EU_POS.png" contain height="100"></v-img> -->
      </v-col>
      <!-- Text -->
      <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pl-0 pt-3" >
        <p class="footerText">Young Service Steward Program (YSS Program, Project No. 2020-1-0144)</p>
        <p class="footerText">The Young Service Steward program benefits from a € 1.240.618 grant from Iceland, Liechtenstein and Norway through the EEA and Norway Grants Fund for Youth Employment.</p>
      </v-col>
      <v-col v-else cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pl-15 pb-5">
        <p class="footerText pa-0 ma-0">Young Service Steward Program (YSS Program, Project No. 2020-1-0144)</p>
        <p class="footerText2 pa-0 ma-0">The Young Service Steward program benefits from a € 1.240.618 grant from Iceland, Liechtenstein and Norway through the EEA and Norway Grants Fund for Youth Employment.</p>
      </v-col>
      <!-- Image -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
        <!-- <v-img class="mt-5" src="img/EVOLVE.png"  max-height="130" max-width="130" contain></v-img> -->
        <!-- <p class="pt-7" style="font-weight: bold; font-size: 20px; color: blue;">Yss Project</p> -->
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data(){
    return {

    }
  }
}
</script>

<style scoped>
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: underline; }

.dividerStyling {
  background-color:#003096;
  border-radius: 4px;
  opacity: 1;
  padding: 2px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.footerText {
  font-family: 'Lato', sans-serif;
  text-align: left;
  color: #6A6A6A;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
}

.footerText2 {
  font-family: 'Lato', sans-serif;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3c3a3a;
  opacity: 1;
  font-size: 14px;
}

</style>