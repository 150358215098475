<template>
  <v-dialog v-model="updatedTemplateDialog" fullscreen>
    <v-card class="pa-5">
      <!-- ToDo:
        1. Fix click on Image, can see it bigger (its own template) 
        2. Fix Norwegian version, same just having an youtube video on the main template (1)
        3. Take into account the type of lession, and or if its just an video ++
          - This will be looked upon in Abit.

        Completed:
          - Bulgaria Version works as it should
      -->

      <!-- Navigation Global -->
      <v-card-title>
        <span class="headline">{{ dataFromParent.name }}</span>
        <v-spacer />
        <v-btn color="error" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- {{contentData}}
      {{userActivityProgress}}
      {{tempCurrentContentObj}} -->

      <!-- Template 1 - Landing Page -->
      <template v-if="displayTemplate == 1">
        <!-- New Layout for Norwegian one here - Change from Bulgarian into Norwegian -->
        <template v-if="dataFromParent.language == 'Norsk'">
          <!-- This is 100% Custom as requested, make more dynamic if its used elsewhere -->
          <template v-if="dataFromParent.id == 60">
            <v-row class="ma-0 pa-0">
              <v-col cols="4">
                <!-- Video -->
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/vLoPiHUZbEw"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-col>
              <v-col cols="6">
                <!-- Description -->
                <p class="title">Hva er voksenpedagogikk</p>
                <p>
                  Voksenpedagogikk, også kalt andragogikk, er «pedagogikk som i
                  sitt innhold, sin organisering og sin utførelse er relevant
                  for og til en viss grad avgrenset av kjennetegnene på det å
                  undervise voksne elever/lærende. Spesielt fokuseres det på
                  forskjellen mellom barn/ungdom og voksne som får implikasjoner
                  for opplæringen.
                </p>
                <p class="title pt-2">Kursinnhold</p>
                <p>
                  Hovedmålet med denne opplæringen er å gi deg som lærer innen
                  grunnleggende ferdigheter inspirasjon, motivasjon og kunnskap
                  for utøvelse av lærergjerningen. Kurset vil rette fokus på det
                  å undervise voksne i arbeidslivet i grunnleggende ferdigheter.
                  Videre vil dette med å gjøre undervisningen så praksisnær som
                  mulig være ett tema. Temaet fra denne modulen vil være
                  relevant for de øvrige fagmodulene, og det forventes at du er
                  i stand til å ta med deg prinsippene fra voksenpedagogikken
                  uansett hvilken grunnleggende ferdighet du underviser i.
                  Opplæringen er laget etter prinsippene for mikrolæring slik at
                  du siden kan gå tilbake og enkelt finne læringsinnholdet du
                  trenger.
                </p>
                <p class="pt-2">Lykke til med læringen.</p>
              </v-col>
            </v-row>
          </template>

          <!-- Custom - Norwegian - Digital skills -->
          <template v-else-if="dataFromParent.id == 87">
            <v-row class="ma-0 pa-0">
              <v-col cols="4">
                <!-- Video -->
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Vh-vfZENh74"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-col>
              <v-col cols="6">
                <!-- Description -->
                <p class="title">Hva er digitale ferdigheter</p>
                <p>
                  Digitale ferdigheter er i dag en forutsetning for å kunne
                  delta aktivt i arbeidsliv, samfunnsliv og opplæring. Å ha
                  grunnleggende digitale ferdigheter vil si å kunne bruke
                  digitale verktøy og tjenester hensiktsmessig og forsvarlig.
                  Digitale ferdigheter innebærer også å utvikle digital
                  dømmekraft gjennom å tilegne seg kunnskap og gode strategier
                  for nettbruk. Bruk av digitale verktøy og tjenester vil si å
                  løse praktiske oppgaver, finne informasjon og kunne
                  kommunisere. Digitale ferdigheter påvirker også måten vi lærer
                  på, vår måte å lese, skrive, regne og utrykke oss muntlig
                </p>
                <p class="title pt-2">Kursinnhold</p>
                <p>
                  Hovedmålet med denne opplæringen er å gi deg som lærer innen
                  grunnleggende digitale ferdigheter inspirasjon, motivasjon og
                  kunnskap for utøvelse av lærergjerningen. Kurset vil være en
                  miks mellom teori og informasjon om praktiske ressurser,
                  tilnærminger og verktøy, tilpasset for opplæring av
                  grunnleggende digitale ferdigheter. Opplæringen er laget etter
                  prinsippene for mikrolæring slik at du siden kan gå tilbake og
                  enkelt finne læringsinnholdet du trenger.
                </p>
                <p class="pt-2">Lykke til med læringen.</p>
              </v-col>
            </v-row>
          </template>
        </template>

        <template v-else>
          <p
            v-if="dataFromParent.description"
            v-text="dataFromParent.description.split('\n')[0]"
            class="pl-2"
          ></p>
        </template>

        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>

        <v-row class="ma-0 pa-0 pt-4">
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="12"
            xs="12"
            v-for="(subCourse, subCourseIndex) in subCoursesInCourse"
            :key="subCourseIndex"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="pa-2"
                :elevation="hover ? 12 : 2"
                @click="getSubcourseData(subCourse)"
              >
                <!-- Image -->
                <v-img :src="subCourse.content_background" width="90%"></v-img>
                <!-- Title -->
                <p class="title">
                  {{ subCourse.content_label
                  }}<v-icon
                    v-if="checkActivityProgress(subCourse.id, null)"
                    color="success"
                    >mdi-check</v-icon
                  >
                </p>
                <!-- Description -->
                <p>{{ subCourse.content_description }}</p>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>

      <!-- Clicked one of the sub courses, display its content pages -->
      <template v-else-if="displayTemplate == 2">
        <!-- Navigation -->
        <v-row class="ma-0 pa-0">
          <v-btn
            x-large
            icon
            title="Go back to Main Course"
            @click="goBackToRootCourse()"
          >
            <v-icon x-large color="primary">mdi-home</v-icon>
          </v-btn>
        </v-row>

        <!-- Subcourse Frontpage -->
        <v-row class="ma-0 pa-0">
          <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
            <v-img
              :src="subCourseDataParent.content_background"
              width="400px"
            ></v-img>
          </v-col>
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12" class="pt-15">
            <p class="title">{{ subCourseDataParent.content_label }}</p>
            <p>{{ subCourseDataParent.content_description }}</p>
            <v-card class="pa-2">
              <v-progress-linear
                class="pl-5"
                color="light-blue lighten-2"
                height="30"
                :value="setProgressScore"
              >
                <span style="font-weight: bold; font-size: 32px"
                  >{{ setProgressScore }} %
                </span>
              </v-progress-linear>
            </v-card>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>

        <!-- SubCourse Contents -->
        <v-row class="ma-0 pa-0 pt-4">
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="12"
            xs="12"
            v-for="(subCourseContents, subCourseContentsIndex) in subCourseData"
            :key="subCourseContentsIndex"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="pa-5"
                height="100%"
                :elevation="hover ? 12 : 2"
                @click="getSubCourseContent(subCourseContents.id)"
                :color="
                  dataFromParent.language == 'Bulgarian' ? '#FFCC80' : '#C8E6C9'
                "
              >
                <p class="text-center">
                  {{ subCourseContents.label
                  }}<v-icon
                    v-if="
                      checkActivityProgress(
                        subCourseDataParent.id,
                        subCourseContents.id
                      )
                    "
                    color="success"
                    >mdi-check</v-icon
                  >
                </p>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>

      <!-- Clicked one of lessons of the sub course -->
      <template v-else-if="displayTemplate == 3">
        <!-- Navigation -->
        <template>
          <v-card
            class="ml-5"
            style="
              width: 6vw;
              position: sticky;
              bottom: 0;
              right: 0;
              position: -webkit-sticky;
            "
          >
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <v-btn
                  x-large
                  icon
                  title="Go back to Main Course"
                  @click="goBackToRootCourse()"
                >
                  <v-icon x-large color="primary">mdi-home</v-icon>
                </v-btn>
                <v-btn
                  x-large
                  icon
                  title="Go back to Chapter Overview"
                  @click="goBackToSubCourse()"
                >
                  <v-icon x-large color="success">mdi-arrow-left-bold</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-btn
                  v-if="
                    this.lessonIndexStatus == 'ok' ||
                    this.lessonIndexStatus == 'end'
                  "
                  x-large
                  icon
                  title="Go back to Previous Chapter"
                  @click="goToPreviousLesson()"
                >
                  <v-icon x-large color="blue">mdi-arrow-left-bold</v-icon>
                </v-btn>
                <v-btn
                  v-if="
                    this.lessonIndexStatus == 'ok' ||
                    this.lessonIndexStatus == 'start'
                  "
                  x-large
                  icon
                  title="Go to the Next Chapter"
                  @click="goToNextLesson()"
                >
                  <v-icon x-large color="blue">mdi-arrow-right-bold</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>

        <!-- Content -->
        <v-row class="ma-0 pa-0">
          <!-- Displaying content based upon type -->

          <v-col
            cols="12"
            :xl="lessonContent.class_list.split('xs')[1]"
            :lg="lessonContent.class_list.split('xs')[1]"
            :md="lessonContent.class_list.split('xs')[1]"
            sm="12"
            xs="12"
            v-for="(lessonContent, lessonContentIndex) in lessonContentData"
            :key="lessonContentIndex"
          >
            <v-card height="100%" flat>
              <template v-if="lessonContent.type == 'text'">
                <p class="pa-5" v-html="lessonContent.content"></p>
              </template>
              <template v-else-if="lessonContent.type == 'task'">
                <!-- <TaskData :gettingChapterContentTask="lessonContent.content"></TaskData> -->
                <div v-if="subCourseDataParent.save_task_responses == 1">
                  <TaskDataWithResponse
                    :gettingChapterContentTask="lessonContent.content"
                    :coursesActivityId="userActivityId"
                    @taskContentDone="setTaskContentDone"
                  ></TaskDataWithResponse>
                </div>
                <div v-else>
                  <TaskData
                    :gettingChapterContentTask="lessonContent.content"
                    @taskContentDone="setTaskContentDone"
                  ></TaskData>
                </div>
              </template>
              <template v-else-if="lessonContent.type == 'image'">
                <!-- <v-img :src="lessonContent.content" height="300" contain @click="goToImageTemplate(lessonContent.content, 5)"></v-img> -->
                <v-img
                  :src="lessonContent.content"
                  height="300"
                  contain
                  @click="openLargerImageTemplate(lessonContent.content)"
                ></v-img>
              </template>
              <template v-else-if="lessonContent.type == 'youtube_video'">
                <iframe
                  v-if="$vuetify.breakpoint.mdAndDown"
                  :src="lessonContent.content"
                  width="100%"
                  style="min-height: 300px"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <iframe
                  v-else
                  style="margin-left: 2%"
                  width="80%"
                  height="500"
                  :src="lessonContent.content"
                  title="Digital SKills"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </template>
              <template v-else-if="lessonContent.type == 'file'">
                <div style="margin-left: calc((100% - 80%) / 2)">
                  <iframe
                    v-if="$vuetify.breakpoint.mdAndDown"
                    :src="lessonContent.content"
                    width="100%"
                    height="800px"
                    style="min-height: 300px"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    v-else
                    style="margin-left: 2%"
                    width="80%"
                    height="800px"
                    :src="lessonContent.content"
                    title="Digital SKills"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </template>
              <template v-else-if="lessonContent.type == 'embededLink'">
                <div style="margin-left: calc((100% - 80%) / 2)">
                  <iframe
                    v-if="$vuetify.breakpoint.mdAndDown"
                    :src="lessonContent.content"
                    width="100%"
                    height="800px"
                    style="min-height: 300px"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    v-else
                    style="margin-left: 2%"
                    width="80%"
                    height="800px"
                    :src="lessonContent.content"
                    title="Digital SKills"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </template>
              <template v-else>
                No type specificed
                {{ lessonContent }}
              </template>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <!-- Non Lesson from Root (image, pds, files, youtube, iframe) - Singular item -->
      <template v-else-if="displayTemplate == 4">
        <!-- Navigation - Back to Root -->
        <v-row class="ma-0 pa-0 pb-10">
          <v-btn
            x-large
            icon
            title="Go back to Main Course"
            @click="goBackToRootCourse()"
          >
            <v-icon x-large color="primary">mdi-home</v-icon>
          </v-btn>
        </v-row>

        <!-- Youtube -->
        <template v-if="subCourseDataParent.type == 'youtube_video'">
          <iframe
            v-if="$vuetify.breakpoint.mdAndDown"
            :src="subCourseDataParent.content"
            width="100%"
            style="min-height: 300px"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            v-else
            style="margin-left: 2%"
            width="80%"
            height="700"
            :src="subCourseDataParent.content"
            title="Digital SKills"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </template>
        <!-- File -->
        <template v-else-if="subCourseDataParent.type == 'files'">
          <div style="margin-left: calc((100% - 80%) / 2)">
            <iframe
              v-if="$vuetify.breakpoint.mdAndDown"
              :src="subCourseDataParent.content"
              width="100%"
              height="800px"
              style="min-height: 300px"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              v-else
              style="margin-left: 2%"
              width="80%"
              height="800px"
              :src="subCourseDataParent.content"
              title="Digital SKills"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </template>
        <!-- Image -->
        <template v-else-if="subCourseDataParent.type == 'image'">
          <v-img
            :src="subCourseDataParent.content"
            height="auto"
            contain
          ></v-img>
        </template>
      </template>

      <!-- Larger Image Template -->
      <template v-else-if="displayTemplate == 5">
        <v-img
          :src="largerImageData"
          height="90vh"
          width="100vw"
          contain
          @click="returnToLesson()"
        ></v-img>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import TaskData from "@/components/TrainingModuleExamples/versionTwo/taskData.vue";
import TaskDataWithResponse from "@/components/TrainingModuleExamples/versionTwo/taskDataWithResponse.vue";
export default {
  components: {
    TaskData,
    TaskDataWithResponse,
  },
  props: {
    userActivityId: {
      type: Number,
    },
  },
  data() {
    return {
      // Access Key to followup API - Hid
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      updatedTemplateDialog: false,
      dataFromParent: "",
      subCoursesInCourse: "",
      // Clicked Sub Course Data
      subCourseDataParent: "",
      subCourseData: "",
      clickedLessonID: "",
      lessonContentData: "",

      previousLesson: "",
      nextLesson: "",
      lessonIndexStatus: "ok",
      //
      displayTemplate: 1,

      // Larget Image
      largerImageData: "",

      // Activity id of user
      activityIdOfUser: "",
      contentData: [],
      userActivityProgress: [],
      tempCurrentContentObj: {},
    };
  },
  methods: {
    // Open Dialog
    openDialog(courseDataFromParent) {
      this.updatedTemplateDialog = true;
      this.dataFromParent = courseDataFromParent;
      this.getAllSubCourses(courseDataFromParent.id);
      setTimeout(() => {
        if (this.userActivityId) {
          this.activityIdOfUser = this.userActivityId;
          this.getUserActivityProgress(this.userActivityId);
        }
      }, 500);
      this.displayTemplate = 1;
    },

    // Close Dialog
    closeDialog() {
      this.updatedTemplateDialog = false;
    },
    // Get all the sub course (even if there is only 1)
    getAllSubCourses(courseID) {
      this.$http
        .get(
          "https://app.followup.prios.no/api/courses/collection_content?mode=getcollectioncontent&courses_collection_id=" +
            courseID,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.subCoursesInCourse = response.data;
        });
    },

    // Get clicked Subcourse Data, based on its type
    getSubcourseData(chapterClicked) {
      this.subCourseDataParent = chapterClicked;
      let clickedSubID = chapterClicked.content;
      this.$http
        .get(
          "https://app.followup.prios.no/api/task_library/lessons?mode=getchapters&courses_id=" +
            clickedSubID,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.subCourseData = response.data;
          if (chapterClicked.type == "lesson") {
            this.displayTemplate = 2;
          } else if (chapterClicked.type == "files") {
            this.setUserActivityProgress(
              chapterClicked.id,
              null,
              "other",
              null
            );
            this.displayTemplate = 4;
          } else if (chapterClicked.type == "youtube_video") {
            this.setUserActivityProgress(
              chapterClicked.id,
              null,
              "other",
              null
            );
            this.displayTemplate = 4;
          } else {
            this.setUserActivityProgress(
              chapterClicked.id,
              null,
              "other",
              null
            );
            this.displayTemplate = 2;
          }
        });
    },

    // Clicking one of the labels of a sub course, to display its data
    getSubCourseContent(idOfContent) {
      this.$http
        .get(
          "https://app.followup.prios.no/api/task_library/lesson_chapters_content?chapter_id=" +
            idOfContent,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.lessonContentData = response.data;
          console.log(response.data);
          this.displayTemplate = 3;
          this.clickedLessonID = idOfContent;
          this.getTheLessonNavigation();
        });
      // this.setUserActivityProgress(idOfContent, null, 'other', hasTask)
      // this.setUserActivityProgress(idOfContent, null, 'other')
    },

    /* Additional Navigation from within the lesson (next lesson and prev lesson)
      - Get clicked index of the lesson.
      - Get length of array and updated index to match
      - Lastly have a check if clicked index are 0 or at the end of the array
    */
    getTheLessonNavigation() {
      let arrayIndex = this.subCourseData.findIndex(
        (el) => el.id == this.clickedLessonID
      );
      let lengthOfArray = this.subCourseData.length;
      let arrayCheckIndex = arrayIndex + 1;
      if (arrayIndex == 0) {
        this.nextLesson = this.subCourseData[arrayIndex + 1];
        this.lessonIndexStatus = "start";
      } else if (arrayCheckIndex == lengthOfArray) {
        this.previousLesson = this.subCourseData[arrayIndex - 1];
        this.lessonIndexStatus = "end";
      } else {
        this.previousLesson = this.subCourseData[arrayIndex - 1];
        this.nextLesson = this.subCourseData[arrayIndex + 1];
        this.lessonIndexStatus = "ok";
      }
    },

    // Open an lesson image into a bigger one
    openLargerImageTemplate(imageData) {
      this.largerImageData = imageData;
      this.displayTemplate = 5;
    },

    // Return from larger image to lesson
    returnToLesson() {
      this.displayTemplate = 3;
      this.largerImageData = "";
    },

    // Making sure all v-models are cleaned
    resetFunction() {
      this.subCourseDataParent = "";
      this.subCourseData = "";
    },

    /* ===== Navigation ===== */

    // Go back to Root Course
    goBackToRootCourse() {
      this.displayTemplate = 1;
      this.resetFunction();
    },

    // Go back to the subCourse after getting into one of its lections.
    goBackToSubCourse() {
      this.displayTemplate = 2;
      this.lessonContentData = "";
    },

    goToPreviousLesson() {
      let idOfContent = this.previousLesson.id;
      this.$http
        .get(
          "https://app.followup.prios.no/api/task_library/lesson_chapters_content?chapter_id=" +
            idOfContent,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.lessonContentData = response.data;
          this.displayTemplate = 3;
          this.clickedLessonID = idOfContent;
          this.getTheLessonNavigation();
        });
    },

    goToNextLesson() {
      let idOfContent = this.nextLesson.id;
      this.$http
        .get(
          "https://app.followup.prios.no/api/task_library/lesson_chapters_content?chapter_id=" +
            idOfContent,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.lessonContentData = response.data;
          this.displayTemplate = 3;
          this.clickedLessonID = idOfContent;
          this.getTheLessonNavigation();
        });
    },

    /* ===== Tracking Functions ===== */

    // Get all user activity progress
    getUserActivityProgress(userActivityId) {
      this.$http
        .get(
          "https://app.followup.prios.no/api/courses/collection_activity_progress?courses_collection_activity_id=" +
            userActivityId,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.userActivityProgress = response.data;
        });
    },

    // Set user activity progress
    // setUserActivityProgress(collectionContentId,lessonChapterId,contentType,hasTask){
    setUserActivityProgress(
      collectionContentId,
      lessonChapterId,
      contentType,
      hasTask
    ) {
      if (this.userActivityId) {
        let findActivityProgress = this.userActivityProgress.find((i) =>
          lessonChapterId
            ? i.courses_collection_content_id == collectionContentId &&
              i.lesson_chapter_id == lessonChapterId
            : i.courses_collection_content_id == collectionContentId &&
              i.lesson_chapter_id == null
        );
        if (!findActivityProgress) {
          let newCreateObj = {
            courses_collection_activity_id: this.userActivityId,
            courses_collection_content_id: collectionContentId,
            lesson_chapter_id: lessonChapterId,
          };
          // if(contentType == "lesson_chapter" && !hasTask){
          if (contentType == "lesson_chapter") {
            this.$http
              .post(
                "https://app.followup.prios.no/api/courses/collection_activity_progress",
                newCreateObj,
                { headers: { Tempaccess: this.accessKey } }
              )
              .then(async (response) => {
                // newCreateObj.id = response.data.insertId
                this.userActivityProgress.push(newCreateObj);
                let checkContentLength = this.userActivityProgress.filter(
                  (i) => i.courses_collection_content_id == collectionContentId
                ).length;
                if (checkContentLength == this.subCourseData.length) {
                  let lessonDoneCreateObj = {
                    courses_collection_activity_id: this.userActivityId,
                    courses_collection_content_id: collectionContentId,
                    lesson_chapter_id: null,
                  };
                  let postLessonComplete = await this.$http.post(
                    "https://app.followup.prios.no/api/courses/collection_activity_progress",
                    lessonDoneCreateObj,
                    { headers: { Tempaccess: this.accessKey } }
                  );
                  this.userActivityProgress.push(lessonDoneCreateObj);
                }
              });
          } else if (!hasTask) {
            this.$http
              .post(
                "https://app.followup.prios.no/api/courses/collection_activity_progress",
                newCreateObj,
                { headers: { Tempaccess: this.accessKey } }
              )
              .then((response) => {
                this.userActivityProgress.push(newCreateObj);
              });
          } else {
            this.tempCurrentContentObj = {
              collectionContentId: collectionContentId,
              lessonChapterId: lessonChapterId,
              contentType: contentType,
              hasTask: false,
            };
          }
        }
      }
    },
    checkActivityProgress(collectionContentId, lessonChapterId) {
      let findActivityProgress = this.userActivityProgress.find((i) =>
        lessonChapterId
          ? i.courses_collection_content_id == collectionContentId &&
            i.lesson_chapter_id == lessonChapterId
          : i.courses_collection_content_id == collectionContentId &&
            i.lesson_chapter_id == null
      );
      if (findActivityProgress) {
        return true;
      } else {
        return false;
      }
    },
    setTaskContentDone() {
      if (this.tempCurrentContentObj.collectionContentId) {
        this.setUserActivityProgress(
          this.tempCurrentContentObj.collectionContentId,
          this.tempCurrentContentObj.lessonChapterId,
          this.tempCurrentContentObj.contentType,
          this.tempCurrentContentObj.hasTask
        );
        this.tempCurrentContentObj = {};
      }
    },
  },
  computed: {
    setProgressScore() {
      let getUserProgressLength = this.userActivityProgress.filter(
        (i) => i.courses_collection_content_id == this.subCourseDataParent.id
      ).length;
      if (getUserProgressLength > this.subCourseData.length) {
        return (this.percentageCompleted = 100);
      } else {
        let calculator =
          (100 * getUserProgressLength) / this.subCourseData.length;
        return (this.percentageCompleted = Math.round(calculator));
      }
    },
  },
  watch: {
    lessonContentData() {
      if (this.lessonContentData.length != 0) {
        let hasTask = false;
        if (this.lessonContentData.map((i) => i.type).includes("task")) {
          hasTask = true;
        }
        this.setUserActivityProgress(
          this.subCourseDataParent.id,
          this.clickedLessonID,
          "lesson_chapter",
          hasTask
        );
      }
    },
  },
};
</script>
