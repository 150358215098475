<template>
  <div>
    <!-- Read the README for more info -->
    <TrainingDialog ref="openTrainingDialog"></TrainingDialog>
    <TrainingDialogTwo ref="openTrainingDialogTwo"></TrainingDialogTwo>

    <!-- <pre>{{courseCollections}}</pre> -->

    <v-row>
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="3"
        sm="12"
        xs="12"
        v-for="(courseCollection, index) in FilteredCoursesByID"
        :key="index"
        class="pa-3"
      >
        <v-card
          class="pa-2"
          @click="$refs.openTrainingDialogTwo.openDialog(courseCollection)"
          height="100%"
        >
          <!-- <p>{{ courseCollection.id }}</p> -->
          <v-img
            v-if="courseCollection.background_image"
            :src="courseCollection.background_image"
            style="height: 150px"
          ></v-img>
          <p class="pt-3 text-center title">{{ courseCollection.name }}</p>
          <p class="textLimiter">{{ courseCollection.description }}</p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TrainingDialog from "./TrainingModuleDialog.vue";
import TrainingDialogTwo from "./TrainingModuleDialogTwo.vue";
export default {
  components: {
    TrainingDialog,
    TrainingDialogTwo,
  },
  data() {
    return {
      // Access Key to followup API
      accessKey: "YmFjNDM2YjMyYTM2NDMxYmI0MzdiOTUwOWI2ZDM0OTU=",
      // 73 - default 999
      tenant: 109,
      courseCollections: [],
    };
  },
  mounted() {
    this.getAllCourseCollections();
    document.title = "Train the trainers in LifeSkills";
  },
  methods: {
    // Get all the courses to display on the page
    getAllCourseCollections() {
      this.courseCollections = [];
      this.$http
        .get(
          `https://app.followup.prios.no/api/courses/collection?mode=getpubliccoursesbytenant&tenant_id=${
            this.tenant
          }&user_id=${null}`,
          { headers: { tempaccess: this.accessKey } }
        )
        .then((response) => {
          response.data.forEach((item) => {
            item.collection_content = [];
            this.courseCollections.push(item);
          });
        });
    },
  },

  computed: {
    // FilteredCoursesByID() {
    //   return this.courseCollections.filter((post) => {
    //     console.log(post.id);

    //     // Belongs to Other Courses
    //     // return (
    //     //   post.id != 67 &&
    //     //   post.id != 98 &&
    //     //   post.id != 73 &&
    //     //   post.id != 74 &&
    //     //   post.id != 75 &&
    //     //   post.id != 76 &&
    //     //   post.id != 77 &&
    //     //   post.id != 78 &&
    //     //   post.id != 79 &&
    //     //   post.id != 80 &&
    //     //   post.id != 81 &&
    //     //   post.id != 82 &&
    //     //   post.id != 83 &&
    //     //   post.id != 71 &&
    //     //   post.id != 68 &&
    //     //   post.id != 66 &&
    //     //   post.id != 65 &&
    //     //   post.id != 110 &&
    //     //   post.id != 111 &&
    //     //   post.id != 112 &&
    //     //   post.id != 113 &&
    //     //   post.id != 114 &&
    //     //   post.id != 115
    //     // );

    //     return (
    //       post.id == 312 ||
    //       post.id == 311 ||
    //       post.id == 123 ||
    //       post.id == 310 ||
    //       post.id == 313 ||
    //       post.id == 126 ||
    //       post.id == 314
    //     );
    //   });
    // },
    FilteredCoursesByID() {
      const orderedIds = [310, 311, 312, 313, 314, 123, 126];
      return this.courseCollections
        .filter((post) => orderedIds.includes(post.id))
        .sort((a, b) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id));
    },
  },
};
</script>
