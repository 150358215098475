<template>
  <v-app>
    <!-- <Sidebar v-if="$vuetify.breakpoint.mdAndDown"></Sidebar>
    <Navbar v-else></Navbar> -->
    <VisitorTracker></VisitorTracker>
    <v-main>
      <router-view/>
    </v-main>

    <Footer class="mt-10"></Footer>
  </v-app>
</template>

<script>
import Navbar from "@/components/Global/Navbar.vue"
import Sidebar from "@/components/Global/Sidebar.vue"
import Footer from "@/components/Global/Footer.vue"
import VisitorTracker from "@/components/VisitTracker/VisitorTracker.vue"

export default {
  name: 'App',
  components:{
    Navbar,
    Sidebar,
    Footer,
    VisitorTracker
  },
  data(){
    return {
      
    }
  }
};
</script>

<style>

  /* Global Project CSS */
  @import './assets/styles/GlobalStyling.css';

  /* Pros-sans 700 - carousel title  Heboo thin - Fonts from googlefonts */
  @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@700&family=Heebo:wght@100&family=Josefin+Sans&family=Lato:wght@400;700&family=Source+Sans+Pro:wght@700&display=swap');

</style>