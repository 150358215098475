<template>
  <div class="homePageWidth">
    <MainCourseDataDialog ref="openThisCourseDataDialog"></MainCourseDataDialog>
    <MainTrainingDialogPhone
      ref="openThisCourseDataDialogPhone"
    ></MainTrainingDialogPhone>
    <v-row>
      <v-col cols="12" class="mt-15"></v-col>
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <p class="trainingChapterTitle">Training Modules</p>
        <p class="trainingChapterDescription">
          Our partnership is still working on the curriculum, resources center
          and the training materials. <br />
          Once they are available, you will be able to find them here.
          <br />
          Below you will find an example of one such module.
        </p>
      </v-col>

      <v-col cols="12">
        <v-card flat>
          <v-row>
            <template v-for="(courseCollection, index) in filteredCategories">
              <v-col
                :key="index"
                v-if="
                  courseCollection.id == 130 ||
                  courseCollection.id == 137 ||
                  courseCollection.id == 138 ||
                  courseCollection.id == 150 ||
                  courseCollection.id == 151 ||
                  courseCollection.id == 152 ||
                  courseCollection.id == 162 ||
                  courseCollection.id == 163 ||
                  courseCollection.id == 164 ||
                  courseCollection.id == 198 ||
                  courseCollection.id == 199 ||
                  courseCollection.id == 200 ||
                  courseCollection.id == 201 ||
                  courseCollection.id == 205 ||
                  courseCollection.id == 207 ||
                  courseCollection.id == 208 ||
                  courseCollection.id == 209 ||
                  courseCollection.id == 210
                "
                cols="12"
                lg="3"
                md="3"
                class="mt-3"
              >
                <v-card
                  v-if="$vuetify.breakpoint.mdAndDown"
                  height="100%"
                  @click="
                    $refs.openThisCourseDataDialogPhone.openDialog(
                      courseCollection
                    )
                  "
                >
                  <v-img
                    v-if="courseCollection.background_image"
                    :src="courseCollection.background_image"
                    height="200px"
                  ></v-img>
                  <p class="pa-2 pb-0">{{ courseCollection.name }}</p>
                  <p class="pa-2 pt-0">{{ courseCollection.description }}</p>
                </v-card>
                <v-card
                  v-else
                  height="100%"
                  @click="
                    $refs.openThisCourseDataDialog.openDialog(courseCollection)
                  "
                >
                  <v-img
                    v-if="courseCollection.background_image"
                    :src="courseCollection.background_image"
                    height="200px"
                  ></v-img>
                  <p class="pa-2 pb-0">{{ courseCollection.name }}</p>
                  <p class="pa-2 pt-0">{{ courseCollection.description }}</p>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MainCourseDataDialog from "@/components/TrainingModuleExamples/versionThree/TrainingModuleDialog.vue";
import MainTrainingDialogPhone from "@/components/TrainingModuleExamples/versionThree/TrainingModuleDialogPhone.vue";
export default {
  name: "Training-Modules",
  components: {
    MainCourseDataDialog,
    MainTrainingDialogPhone,
  },
  data() {
    return {
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      courseCollections: [],
    };
  },
  mounted() {
    this.getAllCourses();
  },
  methods: {
    getAllCourses() {
      this.courseCollections = [];
      this.$http
        .get(
          `https://app.followup.prios.no/api/courses/collection?tenant_id=109`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          console.log("SOMETHING", response.data);
          this.courseCollections = response.data;
        });
    },
    changeCourseLanguage(languageID) {
      this.courseLanguage = languageID;
    },
  },
  computed: {
    filteredCategories() {
      const filteredCourses = this.courseCollections.filter((course) => {
        return course.public === 1 && course.name; // Check if course.name is defined
      });

      const sortedCourses = filteredCourses.sort((a, b) => {
        // Function to extract the number from the course name
        const extractNumber = (name) => {
          const match = name.match(/\d+/);
          return match ? parseInt(match[0]) : NaN;
        };

        const numberA = extractNumber(a.name);
        const numberB = extractNumber(b.name);

        // Check for NaN (non-numeric) values and handle them accordingly
        if (isNaN(numberA) && !isNaN(numberB)) {
          return 1; // Move A to the end
        } else if (!isNaN(numberA) && isNaN(numberB)) {
          return -1; // Move B to the end
        } else if (isNaN(numberA) && isNaN(numberB)) {
          return 0; // Leave them in the same order
        }

        return numberA - numberB; // Compare the extracted numbers
      });

      return sortedCourses;
    },
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}

.trainingChapterTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.trainingChapterDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
</style>
