<template>
  <div>
     <!-- Read the README for more info -->
    <TrainingDialog ref="openTrainingDialog"></TrainingDialog>
    <TrainingDialogTwo ref="openTrainingDialogTwo"></TrainingDialogTwo>


    <!-- <pre>{{courseCollections}}</pre> -->

    <v-row>
      <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" v-for="(courseCollection, index) in FilteredCoursesByID" :key="index" class="pa-3">
        <v-card class="pa-2" @click="$refs.openTrainingDialogTwo.openDialog(courseCollection)" height="100%">
          <v-img v-if="courseCollection.background_image" :src="courseCollection.background_image" style="height: 150px;"></v-img>
          <p class="pt-3 text-center title">{{courseCollection.name}}</p>
          <p class="textLimiter">{{courseCollection.description}}</p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import TrainingDialog from "./TrainingModuleDialog.vue"
import TrainingDialogTwo from "./TrainingModuleDialogTwo.vue"
export default {
  components:{
    TrainingDialog,
    TrainingDialogTwo
  },
  data(){
    return{
      // Access Key to followup API
      accessKey: "YmFjNDM2YjMyYTM2NDMxYmI0MzdiOTUwOWI2ZDM0OTU=",
      // 73 - default 999
      tenant: 109,
      courseCollections:[],
    }
  },
  mounted(){
    this.getAllCourseCollections();
    document.title = "Train the trainers in LifeSkills";
  },
  methods:{
    // Get all the courses to display on the page
    getAllCourseCollections(){
      this.courseCollections = [];
      this.$http.get(`https://app.followup.prios.no/api/courses/collection?mode=getpubliccoursesbytenant&tenant_id=${this.tenant}&user_id=${null}`,{headers:{tempaccess:this.accessKey}}).then(response =>{
        response.data.forEach(item =>{
          item.collection_content = []
          this.courseCollections.push(item);
        })
      })
    },
  },
  
  computed: {
    FilteredCoursesByID() {
      return this.courseCollections.filter(post => {

        // return post.id != 67 
        // && post.id != 66
        // && post.id != 65
        // && post.id != 68
        // && post.id != 72

        // && post.id != 67
        // && post.id != 66
        // && post.id != 65
        // && post.id != 68
        // && post.id != 72
        // && post.id != 83
        // && post.id != 82
        // && post.id != 79

         return post.id == 98
         || post.id == 110
         || post.id == 111
         || post.id == 112
         || post.id == 113
         || post.id == 114
         || post.id == 115
      }) 
    },
  }
}
</script>