<template>
  <v-dialog width="80vw" v-model="imageDialog">
    <v-card>
      <v-img :src="imageData" content @click="closeDialog()"></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data(){
    return {
      imageDialog: false,
      imageData: ""
    }
  },
  methods: {
    // Open Image Dialog
    openDialog(imageToView){
      this.imageDialog = true;
      this.imageData = imageToView;
    },
    // Close Image Dialog
    closeDialog(){
      this.imageDialog = false;
    }
  }
}
</script>