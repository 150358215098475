var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"homePageWidth"},[_c('MainCourseDataDialog',{ref:"openThisCourseDataDialog"}),_c('MainTrainingDialogPhone',{ref:"openThisCourseDataDialogPhone"}),_c('v-row',[_c('v-col',{staticClass:"mt-15",attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"trainingChapterTitle"},[_vm._v("Training Modules")]),_c('p',{staticClass:"trainingChapterDescription"},[_vm._v(" Our partnership is still working on the curriculum, resources center and the training materials. "),_c('br'),_vm._v(" Once they are available, you will be able to find them here. "),_c('br'),_vm._v(" Below you will find an example of one such module. ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_vm._l((_vm.filteredCategories),function(courseCollection,index){return [(
                courseCollection.id == 130 ||
                courseCollection.id == 137 ||
                courseCollection.id == 138 ||
                courseCollection.id == 150 ||
                courseCollection.id == 151 ||
                courseCollection.id == 152 ||
                courseCollection.id == 162 ||
                courseCollection.id == 163 ||
                courseCollection.id == 164 ||
                courseCollection.id == 198 ||
                courseCollection.id == 199 ||
                courseCollection.id == 200 ||
                courseCollection.id == 201 ||
                courseCollection.id == 205 ||
                courseCollection.id == 207 ||
                courseCollection.id == 208 ||
                courseCollection.id == 209 ||
                courseCollection.id == 210
              )?_c('v-col',{key:index,staticClass:"mt-3",attrs:{"cols":"12","lg":"3","md":"3"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-card',{attrs:{"height":"100%"},on:{"click":function($event){return _vm.$refs.openThisCourseDataDialogPhone.openDialog(
                    courseCollection
                  )}}},[(courseCollection.background_image)?_c('v-img',{attrs:{"src":courseCollection.background_image,"height":"200px"}}):_vm._e(),_c('p',{staticClass:"pa-2 pb-0"},[_vm._v(_vm._s(courseCollection.name))]),_c('p',{staticClass:"pa-2 pt-0"},[_vm._v(_vm._s(courseCollection.description))])],1):_c('v-card',{attrs:{"height":"100%"},on:{"click":function($event){return _vm.$refs.openThisCourseDataDialog.openDialog(courseCollection)}}},[(courseCollection.background_image)?_c('v-img',{attrs:{"src":courseCollection.background_image,"height":"200px"}}):_vm._e(),_c('p',{staticClass:"pa-2 pb-0"},[_vm._v(_vm._s(courseCollection.name))]),_c('p',{staticClass:"pa-2 pt-0"},[_vm._v(_vm._s(courseCollection.description))])],1)],1):_vm._e()]})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }