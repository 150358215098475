var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MainTrainingDialogContentPhone',{ref:"displayCourseContent"}),_c('v-dialog',{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.courseDialogMenu),callback:function ($$v) {_vm.courseDialogMenu=$$v},expression:"courseDialogMenu"}},[_c('v-card',[_c('v-app-bar',{staticStyle:{"top":"0","position":"sticky","z-index":"2"},attrs:{"clipped-left":"","color":"primaryColor","extension-height":8}},[_c('header',[_c('span',[_vm._v(_vm._s(_vm.courseData.name))])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"rows":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{staticClass:"ml-1",attrs:{"accordion":""}},_vm._l((_vm.courseMenu),function(menuItem,index){return _c('v-expansion-panel',{key:index.id},[(menuItem.type == 'lesson')?_c('v-expansion-panel-header',{on:{"click":function($event){return _vm.getAllLessonChapters(menuItem.content)}}},[_c('span',[_vm._v(_vm._s(menuItem.content_label))])]):_vm._e(),(menuItem.type == 'lesson')?_c('v-expansion-panel-content',[_vm._l((_vm.courseLessonMenu),function(lesson,index){return _c('v-card',{key:index.id,staticClass:"ma-0 pa-0",attrs:{"tile":"","flat":""},on:{"click":function($event){return _vm.$refs.displayCourseContent.openLessonDialog(
                        lesson,
                        lesson.label
                      )}}},[(lesson.id == 290)?_c('v-card-text',{staticClass:"ma-0 pa-0",staticStyle:{"display":"none"}}):_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('p',{staticClass:"ma-0 pa-0 pt-4 pb-4"},[_vm._v(_vm._s(lesson.label))])])],1)})],2):(menuItem.type == 'files')?_c('v-card',{on:{"click":function($event){return _vm.$refs.displayCourseContent.openFilesDialog(
                    menuItem,
                    menuItem.content_label
                  )}}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"row":""}},[_c('v-col',{staticClass:"ma-0 pa-0 pt-2 pb-2 pl-5",attrs:{"cols":"10"}},[_vm._v(_vm._s(menuItem.content_label))]),_c('v-col',{staticClass:"ma-0 pa-0 pt-2 pb-2 pl-4",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1)],1)],1):_c('v-card',{on:{"click":function($event){return _vm.$refs.displayCourseContent.openDialog(
                    menuItem,
                    menuItem.content_label
                  )}}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"row":""}},[_c('v-col',{staticClass:"ma-0 pa-0 pt-2 pb-2 pl-5",attrs:{"cols":"10"}},[_vm._v(_vm._s(menuItem.content_label))]),_c('v-col',{staticClass:"ma-0 pa-0 pt-2 pb-2 pl-4",attrs:{"cols":"1"}},[(menuItem.type == 'youtube_video')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-youtube")]):(
                          menuItem.type == 'external_learning_content'
                        )?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-link")]):(menuItem.type == 'task')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-list-checkbox")]):(menuItem.type == 'learning_scene')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")]):(menuItem.type == 'files')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")]):_vm._e()],1)],1)],1)],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }