<template>
  <div>
    <TrainingDialogTwo ref="openTrainingDialogTwo"></TrainingDialogTwo>
    <v-row>
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="3"
        sm="12"
        xs="12"
        v-for="(courseCollection, index) in FilteredCoursesByID"
        :key="index"
        class="pa-3"
      >
        <v-card
          class="pa-2"
          @click="$refs.openTrainingDialogTwo.openDialog(courseCollection)"
          height="100%"
        >
          <v-img
            v-if="courseCollection.background_image"
            :src="courseCollection.background_image"
            style="height: 150px"
          ></v-img>
          <p class="pt-3 text-center title">{{ courseCollection.name }}</p>
          <p class="textLimiter">{{ courseCollection.description }}</p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TrainingDialogTwo from "@/components/TrainingModuleExamples/versionTwo/TrainingDialog.vue";
export default {
  components: {
    TrainingDialogTwo,
  },
  data() {
    return {
      // Access Key to followup API
      accessKey: "YmFjNDM2YjMyYTM2NDMxYmI0MzdiOTUwOWI2ZDM0OTU=",
      // 73 - default 999
      tenant: 109,
      courseCollections: [],
    };
  },
  mounted() {
    this.getAllCourseCollections();
    document.title = "Train the trainers in Digital Skill";
  },
  methods: {
    // Get all the courses to display on the page
    getAllCourseCollections() {
      this.courseCollections = [];
      this.$http
        .get(
          `https://app.followup.prios.no/api/courses/collection?mode=getpubliccoursesbytenant&tenant_id=${
            this.tenant
          }&user_id=${null}`,
          { headers: { tempaccess: this.accessKey } }
        )
        .then((response) => {
          response.data.forEach((item) => {
            item.collection_content = [];
            this.courseCollections.push(item);
          });
        });
    },
  },
  computed: {
    FilteredCoursesByID() {
      return this.courseCollections.filter((post) => {
        return post.id == 130;

        //  return post.id == 67
        //  || post.id == 66
        //  || post.id == 65
        //  || post.id == 68
      });
    },
  },
};
</script>
