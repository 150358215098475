// Import packages
import Vue from "vue";
import VueRouter from "vue-router";

// Import Pages
import Home from "../views/Home.vue";
import HomePageTwo from "../views/HomePageTwo.vue";
import About from "../views/About.vue";
import News from "../views/News.vue";
import Partners from "../views/Partner.vue";
import TrainingModules from "../views/TrainingModules.vue";
import Resources from "../views/Resources.vue";

import HungarianPage from "../views/HungarianPage.vue";

import RomanianPage from "../views/RomanianPage.vue";
import CatalanPage from "../views/CatalanPage.vue";

// Testing Pages
import TestingPage from "../views/Testing.vue";
import Profile from "../views/Profile.vue";
// Example Training Modules versions
import TrainingExampleOne from "../views/TrainingExampleOne.vue";
import TrainingExampleTwo from "../views/TrainingExampleTwo.vue";
import TrainingExampleThree from "../views/TrainingExampleThree.vue";

// Digital Skill Pages - 20.10.2023
import NewDigitalSkill from "../views/digitalSkill.vue";
import NewDigitalSkillRO from "../views/digitalSkillRO.vue";
import NewDigitalSkillHU from "../views/digitalSkillHU.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Yss", component: TrainingModules },
  { path: "/digitalskills", name: "Yss", component: HomePageTwo },
  { path: "/hungary", name: "Yss", component: HungarianPage },
  { path: "/romania", name: "Yss", component: RomanianPage },
  { path: "/catalan", name: "Yss", component: CatalanPage },
  { path: "/trainingone", name: "Trainingv1", component: TrainingExampleOne },
  { path: "/trainingtwo", name: "Trainingv2", component: TrainingExampleTwo },
  {
    path: "/trainingthree",
    name: "Trainingv3",
    component: TrainingExampleThree,
  },
  {
    path: "/digitalskill",
    name: "digitalskill",
    component: NewDigitalSkill,
  },
  {
    path: "/digitalskillromania",
    name: "digitalskill hungary",
    component: NewDigitalSkillRO,
  },
  {
    path: "/digitalskillhungary",
    name: "digitalskill romania",
    component: NewDigitalSkillHU,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
