<template>
  <v-dialog v-model="imageDialog">
    <v-card @click="closeDialog()">
      <!-- Hungary v1 -->
      <v-img
        v-if="imageType == 'hungary'"
        src="@/assets/images/hungarytwo.png"
        height="auto"
        width="auto"
        contain
      ></v-img>
      <!-- English v1 -->
      <v-img
        v-if="imageType == 'english'"
        src="@/assets/images/english.jpg"
        height="auto"
        width="auto"
        contain
      ></v-img>
      <!-- Romania v1 -->
      <v-img
        v-if="imageType == 'romania'"
        src="@/assets/images/romania.jpg"
        height="auto"
        width="auto"
        contain
      ></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      imageDialog: false,
      imageType: "",
    };
  },
  methods: {
    openDialog(imageType) {
      this.imageDialog = true;
      this.imageType = imageType;
    },
    closeDialog() {
      this.imageDialog = false;
    },
  },
};
</script>
