<template>
  <div class="pa-3" v-if="questionData.length != 0">
    <v-stepper v-model="questionStepper" non-linear>
      <v-stepper-items>
        <v-stepper-content
          v-for="(item, index) in questionData"
          :step="index + 1"
          :ripple="false"
          :key="index"
        >
          <v-card class="mb-5 textColorList elevation-0">
            <v-layout row wrap>
              <v-flex xs6>
                <p>
                  Number:
                  <span class="displayCircleStyle"
                    >{{ questionStepper }}/{{ questionData.length }}</span
                  >
                </p>
              </v-flex>
            </v-layout>
            <div>
              <p class="subheading">{{ item.question }}</p>
              <div
                v-if="
                  item.media_type &&
                  item.media_url &&
                  questionStepper == index + 1
                "
              >
                <div v-if="item.media_type == 'image'">
                  <v-layout row justify-center>
                    <v-flex xs4>
                      <v-img :src="item.media_url" aspect-ratio="1"> </v-img>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else-if="item.media_type == 'audio'">
                  <audio
                    :id="'mediaId_' + item.id"
                    controls
                    :src="item.media_url"
                  ></audio>
                </div>
              </div>
            </div>
            <div class="" v-if="item.type == 'text_multiple'">
              <v-radio-group
                v-model="item.question_response_answer"
                class="radioAlignClass"
                :disabled="item.question_response_id"
              >
                <v-radio
                  :label="questionOption"
                  :value="questionOption"
                  v-for="(questionOption, optionIndex) in item.options"
                  :key="optionIndex"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="" v-if="item.type == 'text_sort'">
              <div v-if="item.question_response_id">
                <p
                  v-for="(questionOption, optionIndex) in item.options"
                  :key="optionIndex"
                >
                  {{ optionIndex + 1 }}. {{ questionOption }}
                </p>
              </div>
              <div v-else>
                <draggable
                  v-model="item.options"
                  group="options"
                  class="mt-1 pt-1"
                  :sort="true"
                  ghostClass="ghost"
                  animation="200"
                  disabled
                  @change="setSortAnswer(item)"
                >
                  <transition-group type="transition">
                    <div
                      draggable
                      class="optiondrag pa-2 sortableQuestion"
                      v-for="(questionOption, optionIndex) in item.options"
                      :key="optionIndex"
                    >
                      <p>{{ optionIndex + 1 }}. {{ questionOption }}</p>
                    </div>
                  </transition-group>
                </draggable>
              </div>
            </div>
            <div class="" v-if="item.type == 'text_fill'">
              <v-text-field
                box
                label="Your answer..."
                v-model="item.question_response_answer"
                :disabled="item.question_response_id"
              ></v-text-field>
            </div>
            <div v-if="item.type == 'text_long'">
              <v-textarea
                box
                label="Your answer..."
                v-model="item.question_response_answer"
                :disabled="item.question_response_id"
              ></v-textarea>
            </div>
            <div v-if="item.type == 'audio_understanding_multiple'">
              <v-radio-group
                v-model="item.question_response_answer"
                class="radioAlignClass"
                :disabled="item.question_response_id"
              >
                <v-radio
                  :label="questionOption"
                  :value="questionOption"
                  v-for="(questionOption, optionIndex) in item.options"
                  :key="optionIndex"
                ></v-radio>
              </v-radio-group>
            </div>
            <div v-if="item.type == 'audio_understanding_text'">
              <v-textarea
                box
                label="Your answer..."
                v-model="item.question_response_answer"
                :disabled="item.question_response_id"
              ></v-textarea>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
      <template>
        <div class="ml-2 mr-2">
          <v-layout row>
            <v-flex xs8>
              <v-pagination
                id="paginationItem"
                circle
                :color="setPagnationColors"
                v-model="questionStepper"
                :length="questionData.length"
              ></v-pagination>
            </v-flex>
            <v-flex class="text-xs-right" xs4>
              <v-btn
                v-if="questionStepper < questionData.length"
                @click="questionStepper++"
                >Next</v-btn
              >
              <v-btn @click="sendAnswers" :disabled="questionSubmitReady">
                Submit answers
              </v-btn>
              <v-menu open-on-hover bottom offset-y dark>
                <template v-slot:activator="{ on }">
                  <v-btn icon color="primary" v-on="on"> ? </v-btn>
                </template>
                <v-card class="pa-2">
                  <p>
                    Submit all answers which has a value(marked in green)<br />
                    After submitting you will be able to submit all remaining
                    questions right away or later<br />
                    After submitting you will no longer be able to edit the
                    submitted answer
                  </p>
                </v-card>
              </v-menu>
            </v-flex>
          </v-layout>
        </div>
      </template>
    </v-stepper>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  props: {
    gettingChapterContentTask: {
      type: String,
    },
    coursesActivityId: {
      type: Number,
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      questionData: [],
      questionStepper: 1,
      selectedValue: null,
      courseResponse: null,
    };
  },
  methods: {
    setPaginationStatusColors() {
      let selectAllItems = document.querySelectorAll(".v-pagination__item");
      selectAllItems.forEach((item, index) => {
        let findQuestionAnswer = this.questionData[index];
        if (findQuestionAnswer.question_response_answer) {
          item.style.border = "2px solid #4caf50";
        } else {
          item.style.border = "2px solid #ff5252";
        }
      });
    },
    getTask(courseResponseId) {
      this.questionData = [];
      axios
        .get(
          `api/task_library/chapters?mode=getsinglechapterandquestions&chapter_id=${parseInt(
            this.taskId
          )}&course_response_id=${courseResponseId}`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          response.data.questions.forEach((item) => {
            if (item.options) {
              item.options = item.options.split("---");
              for (let i = item.options.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * i);
                let temp = item.options[i];
                item.options[i] = item.options[j];
                item.options[j] = temp;
              }
            }
            this.questionData.push(item);
          });
          this.questionStepper = 1;
          this.setCurrentQuestionValue();
        });
    },
    getCourseResponse() {
      axios
        .get(
          `api/courses/collection_activity_response?mode=getresponsefortask&activity_id=${this.coursesActivityId}&task_id=${this.taskId}`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          let courseResponseId = null;
          if (response.data.length == 0) {
            this.createNewResponseForTask();
          } else {
            this.courseResponse = response.data[0];
            courseResponseId = this.courseResponse.id;
            this.getTask(courseResponseId);
          }
        });
    },
    createNewResponseForTask() {
      let newResponse = {
        course_activity_id: this.coursesActivityId,
        task_id: this.taskId,
      };
      axios
        .post("api/courses/collection_activity_response", newResponse)
        .then((response) => {
          newResponse.id = response.data.insertId;
          this.courseResponse = newResponse;
          this.getTask(newResponse.id);
        });
    },
    setSortAnswer(question) {
      question.question_response_answer = question.options.join("---");
    },
    sendAnswers() {
      let tempArr = [];
      this.questionData.forEach((item) => {
        if (item.question_response_answer && !item.question_response_id) {
          tempArr.push([
            this.courseResponse.id,
            item.id,
            item.question_response_answer,
          ]);
          item.question_response_id = "tempId";
        }
      });
      if (tempArr.length != 0) {
        axios
          .post("api/courses/collection_question_response", tempArr)
          .then(() => {
            if (
              !this.questionProgress
                .map((i) => i.question_response_id)
                .includes(null)
            ) {
              this.$emit("taskContentDone");
            }
          });
      }
    },
    setCurrentQuestionValue() {
      let findQuestion = this.questionData[this.questionStepper - 1];
      if (findQuestion && findQuestion.question_response_answer) {
        if (findQuestion.type == "text_sort") {
          findQuestion.options =
            findQuestion.question_response_answer.split("---");
          this.selectedValue = null;
        }
        this.selectedValue = findQuestion.question_response_answer;
      } else {
        this.selectedValue = null;
      }
    },
  },
  mounted() {
    this.getCourseResponse();
    setTimeout(() => {
      this.setPaginationStatusColors();
    }, 1000);
  },
  computed: {
    questionSubmitReady() {
      if (
        this.questionData.filter(
          (i) => i.question_response_answer && !i.question_response_id
        ).length != 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    setPagnationColors() {
      let findQuestion = this.questionData[this.questionStepper - 1];
      if (findQuestion.question_response_answer) {
        return "success";
      } else if (!findQuestion.question_response_answer) {
        return "error";
      } else {
        return "";
      }
    },
  },
  watch: {
    questionStepper() {
      this.setCurrentQuestionValue();
      this.setPaginationStatusColors();
    },
  },
};
</script>

<style scoped>
.radioAlignClass {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
}
.sortableQuestion:hover {
  background-color: #546e7a;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
.displayCircleStyle {
  height: 30px;
  width: 30px;
  background-color: #f6f7fa;
  color: #1a8096;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
}
.textColorList {
  color: #616161;
}
</style>
