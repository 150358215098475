import nb from "./lang.nb.json";
import en from "./lang.en.json";
import de from "./lang.de.json";
import el from "./lang.el.json";
import es from "./lang.es.json";
import pl from "./lang.pl.json";

export default {
  en,
  nb,
  de,
  el,
  es,
  pl
};
