<template>
  <div class="homePageWidth">
    <v-row>
      <v-col cols="12" class="mt-15"></v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="2" xs="12">
        <v-img src="img/lichenLogo.jpg" contain height="100"></v-img>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xs" cols="12">
        <p class="mainHeader trainingHeaderTitle ml-5 text-center">
          Yss Project
        </p>
        <!-- <p class="mainSubHeader ml-5 text-center" style="font-size:22px;">Train the trainers in Lifeskills</p> -->
      </v-col>
      <v-col v-else cols="12" xl="8" lg="8" md="8" sm="8" xs="12">
        <p class="mainHeader trainingHeaderTitle ml-5 mt-5">Yss Project</p>
        <!-- <p class="mainSubHeader ml-5" style="font-size:22px;">Train the trainers in Lifeskills</p> -->
      </v-col>
      <v-col cols="12" class="mt-0 pt-0">
        <p class="ma-0 pa-0">
          Working together for a <span style="color: #20d17f">green</span>,
          <span style="color: #ff0016">competitive</span> and
          <span style="color: #003096">inclusive</span> Europe.
        </p>
        <p style="color: #6a6a6a; font-size: 14px">
          NEET 4 Needs – YSS program for a better future
        </p>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-divider class="mb-2"></v-divider>
      </v-col>
      <!-- <v-col cols="3"></v-col>
      <v-col cols="8">
        <p class="mainHeader trainingHeaderTitle">Yss Project</p>
        <p class="mainSubHeader pb-15" style="font-size:22px;">Train the trainers in Lifeskills</p>
      </v-col> -->

      <v-col cols="12" xl="8" lg="8" md="8" sm="8" xs="12" class="mt-8">
        <v-row>
          <v-col cols="12">
            <p class="mb-15" style="font-size: 30px; font-weight: bold">
              Train the trainers in Lifeskills
            </p>
          </v-col>
          <v-col cols="12">
            <p class="mb-0 pb-0 ml-2">Select language</p>
            <NavigationFlags />
          </v-col>
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12">
            <v-card
              flat
              height="100%"
              @click="$refs.openImageDialog.openDialog('english')"
            >
              <v-img
                v-if="$vuetify.breakpoint.mdAndUp"
                src="../assets/images/english.jpg"
                width="auto"
                height="auto"
                contain
              ></v-img>
              <v-img
                v-else
                src="../assets/images/english.jpg"
                width="100%"
                height="200px"
                contain
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
            <v-card flat height="100%" class="pl-5">
              <p class="title text-center">Introduction to the training</p>
              <p style="font-size: 18px">
                The main objective of this training is to give you as a life
                skills educator inspiration, motivation and knowledge. You will
                find information about the programs as well as practical
                resource, approaches and tools, adapted for training the NEETs
                in life skills. The training is created according to the
                principles of micro-learning. You can take the whole course or
                pick the modules most relevant for you. In addition, the
                learning sections will have a function as a reference work you
                can open to find information when needed. We recommend following
                the learning process as shown in the model.
              </p>
              <p style="font-size: 18px; font-weight: bold">
                Good luck with the learning.
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        style="border-top: 1px solid grey"
        class="mb-10 mt-10"
      ></v-col>

      <v-col cols="12">
        <!-- <TrainingPage></TrainingPage> -->
        <TrainingMPage></TrainingMPage>
      </v-col>
    </v-row>
    <ImageDialog ref="openImageDialog" />
  </div>
</template>

<script>
// import TrainingPage from "@/components/TrainingModules/TrainingPage.vue"
import TrainingMPage from "@/components/TrainingM/TrainingPage.vue";
import NavigationFlags from "@/components/Global/navigationFlags.vue";
import ImageDialog from "@/components/Global/imageDialog.vue";
export default {
  components: {
    // TrainingPage
    TrainingMPage,
    ImageDialog,
    NavigationFlags,
  },
  data() {
    return {};
  },
};
</script>
