<template>
  <div class="homePageWidth">
    <v-row>
      <v-col cols="12" class="mt-15"></v-col>

      <!-- ===== Top Section ===== -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="2" xs="12">
        <v-img src="img/lichenLogo.jpg" contain height="100"></v-img>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xs" cols="12">
        <p class="mainHeader trainingHeaderTitle ml-5 text-center">
          Yss Project
        </p>
      </v-col>
      <v-col v-else cols="12" xl="8" lg="8" md="8" sm="8" xs="12">
        <p class="mainHeader trainingHeaderTitle ml-5 mt-5">Yss Project</p>
      </v-col>
      <v-col cols="12" class="mt-0 pt-0">
        <p class="ma-0 pa-0">
          Working together for a <span style="color: #20d17f">green</span>,
          <span style="color: #ff0016">competitive</span> and
          <span style="color: #003096">inclusive</span> Europe.
        </p>
        <p style="color: #6a6a6a; font-size: 14px">
          NEET 4 Needs – YSS program for a better future
        </p>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-divider class="mb-2"></v-divider>
      </v-col>

      <!-- ===== Introduction to MOOC ===== -->
      <v-col cols="12" xl="8" lg="8" md="8" sm="8" xs="12" class="mt-8">
        <v-row>
          <v-col cols="12">
            <p class="mb-15" style="font-size: 30px; font-weight: bold">
              Online digital skills training
            </p>
          </v-col>
        </v-row>
      </v-col>

      <!-- ===== The Training Module itself ===== -->
      <v-col cols="12">
        <TrainingMPage></TrainingMPage>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TrainingMPage from "@/components/TrainingModuleExamples/versionThree/TrainingModuleVersionThree.vue";
export default {
  components: {
    TrainingMPage,
  },
  data() {
    return {};
  },
};
</script>
